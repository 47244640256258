import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {mark} from '../../../utils/marked';
import st from './banner.module.scss';

export const Banner = () => {
  const {
    restApiAboutUsPage: {
      Banner_section: {Title, Description, Background_image},
    },
  } = query();
  return (
    <div className={st.wrapper} style={{backgroundImage: `url(${Background_image.url})`}}>
      <div className={st.section}>
        <div className={st.title} dangerouslySetInnerHTML={mark(Title)}></div>
        <div className={st.paragraph} dangerouslySetInnerHTML={mark(Description)}></div>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiAboutUsPage {
        Banner_section {
          Title
          Header
          Description
          Background_image {
            url
            name
          }
        }
      }
    }
  `);
