import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {mark} from '../../../utils/marked';
import st from './clutch.module.scss';
import {Button} from '../../../components/button/button';

export const Clutch = () => {
  const {
    restApiAboutUsPage: {
      clutch: {title, subtitle, image, button},
    },
  } = query();
  return (
    <div className={st.section}>
      <div className={st.section__wrapper}>
        <div className={st.content__wrapper}>
          <div className={st.content}>
            <div className={st.title} dangerouslySetInnerHTML={mark(title)}></div>
            <div className={st.subtitle} dangerouslySetInnerHTML={mark(subtitle)}></div>
            <Button to={button?.url}>{button?.text}</Button>
            {/* <div className={st.image__mobile} style={{backgroundImage: `url(${image?.url})`}} />
             */}
          </div>
          <div className={st.widget}>
            <div
              className='clutch-widget'
              data-url='https://widget.clutch.co'
              data-widget-type='3'
              data-height='370'
              data-clutchcompany-id='1247846'
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiAboutUsPage {
        clutch {
          title
          subtitle
          image {
            name
            url
          }
          button {
            url
            text
          }
        }
      }
    }
  `);
