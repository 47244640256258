import React from 'react';
import {Banner} from './banner/banner';
import {WhoWeAre} from './who-we-are/who-we-are';
import {Clutch} from './clutch/clutch';
import {MeetUs} from './meet-us/meet-us';
import {More} from './more/more';
import {useStaticQuery, graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import {Passion} from './passion/passion';
import {Strength} from './strength/strength';
import {Innovation} from './innovation/innovation';

export const About = () => {
  const {
    restApiAboutUsPage: {seo},
  } = query();
  return (
    <>
      <Helmet meta={[{name: 'description', content: seo.description}]}>
        <title>{seo.title}</title>
        <script type='text/javascript' src='https://widget.clutch.co/static/js/widget.js' defer></script>
      </Helmet>
      <Banner />
      <Passion />
      <Strength />
      {/* <WhoWeAre /> */}
      <Clutch />
      <Innovation />
      <MeetUs />
      <More />
    </>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiAboutUsPage {
        seo {
          title
          description
        }
      }
    }
  `);
