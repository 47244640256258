import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {Button} from '../../../components/button/button';
import {mark} from '../../../utils/marked';
import st from './more.module.scss';

export const More = () => {
  const {
    restApiAboutUsPage: {
      know_more: {title, button_one, button_two},
    },
  } = query();
  return (
    <div className={st.section}>
      <div className={st.title}>
        Want to Know More <strong>About Us?</strong>
      </div>
      <div className={st.button__wrapper}>
        <Button to={button_one?.url}>What we do</Button>
        <Button to={button_two?.url} className='secondary'>
          Our IT Community
        </Button>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiAboutUsPage {
        know_more {
          title
          button_two {
            text
            url
          }
          button_one {
            text
            url
          }
        }
      }
    }
  `);
