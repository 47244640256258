import React from 'react';
import st from './passion.module.scss';

export const Passion = () => {
  return (
    <div className={st.section}>
      <h2 className={st.title}>
        We Built This Company <strong>With Passion for People and Tech</strong>
      </h2>
      <div className={st.wrapper}>
        <div className={st.subtitle}>
          Blue House helps companies build high-end products by empowering their internal tech teams with IT Experts
          from the Community.
        </div>
        <div className={st.content}>
          We have been providing teams and individuals that help build innovative solutions as well as enhance
          high-performing internal development teams. We understand business and we only work with people who are the
          very best in their tech ﬁeld.
        </div>
      </div>
      <div className={st.image}>
        <img src='/passion.svg' />
      </div>
      <div className={st.image_mobile}>
        <img className={st.img} src='/passion_big.svg' />
        <img src='/passion_1.svg' />
        <img src='/passion_button.svg' />
        <img src='/passion_2.svg' />
        <img src='/passion_button.svg' />
        <img src='/passion_3.svg' />
      </div>
    </div>
  );
};
