import React from 'react';
import {Button} from '../../../components/button/button';
import st from './strength.module.scss';
import {URLS} from '../../../components/nav/nav';

export const Strength = () => {
  return (
    <div className={st.section}>
      <div className={st.wrapper}>
        <h2 className={st.title}>
          Our Strength Lies
          <br />
          <span className={st.bold}>in Our Community</span>
        </h2>
        <div className={st.wrapper_subtext}>
          <div className={st.subtitle}>
            Blue House is not only a company - it’s a Community of skilled professionals who want to bring value to what
            they do every day.
          </div>
          <div className={st.content}>
            We said “no” to projects based on repetition and being devoid of an individual approach. We value
            out-of-the-box thinking because creativity is a driving force behind what we do.
          </div>
        </div>
      </div>

      <div className={st.card}>
        <div className={st.card_content}>
          <div className={st.card_title_wrapper}>
            <div className={st.card_name}>
              <div className={st.card_title}>
                <div>Patryk Dumicz</div>
                <a href='https://www.linkedin.com/in/dumicz/'>
                  <img src='/linkedin_2.svg' />
                </a>
              </div>
              <div className={st.card_subtitle}>Co-founder</div>
            </div>
          </div>

          <div className={st.card_text}>
            There is a high demand for technology skills on the market. It is hard for the companies to acquire Experts
            and even harder to keep them motivated - especially once the project goes into the maintenance stage.
          </div>
        </div>
        <div className={st.image}>
          <img src={'/patryk.png'} />
        </div>
      </div>
      <div className={st.card}>
        <div className={st.card_content}>
          <div className={st.card_title_wrapper}>
            <div className={st.card_name}>
              <div className={st.card_title}>
                <div>Michał Śpiczko</div>
                <a href='https://www.linkedin.com/in/michal-spiczko/'>
                  <img src='/linkedin_2.svg' />
                </a>
              </div>
              <div className={st.card_subtitle}>Co-founder</div>
            </div>
          </div>

          <div className={st.card_text}>
            As a technology company, at the core of our business is a complete understanding of the unique needs of
            innovative technology projects. We leverage that understanding to build exceptional teams.
          </div>
        </div>
        <div className={st.image}>
          <img src={'/michal.png'} />
        </div>
      </div>

      <div className={st.button}>
        <Button to={URLS.JOIN}>Join Us</Button>
      </div>
    </div>
  );
};
