import React from 'react';
import {Layout} from '../components/layout/layout';
import {About} from '../pages-components/about/about';

const AboutPage = () => (
  <Layout>
    <About />
  </Layout>
);

export default AboutPage;
