import React from 'react';
import st from './innovation.module.scss';
import {Button} from '../../../components/button/button';

export const Innovation = () => {
  return (
    <div className={st.section}>
      <div className={st.title}>
        Our Initiatives and Partnerships
        <br />
        <strong>Driven by Innovation</strong>
      </div>

      <div className={st.grid}>
        <div className={st.grid_row}>
          <JourneyCard />
          <AiToolCard />
        </div>
        <div className={st.grid_row}>
          <CrmBotCard />
        </div>
        <div className={st.grid_row}>
          <GqpCard />
          <MedicaCard />
        </div>
      </div>
      <Button className='primary' stClassName={st.section_button}>
        Send us a question
      </Button>
    </div>
  );
};

const JourneyCard = () => (
  <div className={`${st.card} ${st.journey}`}>
    <div className={st.card_title}>
      Working on <span className={st.card_title_blue}>JourneyPro</span> product which simplifies complex client
      processes
    </div>
    <div className={st.card_description}>
      JourneyPro is a framework for designing, building, and implementing complex customer journeys into the client's IT
      ecosystem without any coding knowledge.
    </div>
    <div className={st.card_image}>
      <img src={'/journeyPro.svg'} alt={'Journey Pro'} />
    </div>{' '}
  </div>
);

const AiToolCard = () => (
  <div className={`${st.card} ${st.aitool}`}>
    <div className={st.card_title}>Engaged in building an AI-tool to combat hate speech on the Internet</div>
    <div className={st.card_description}>
      This tool allows detecting hate on the Internet through real-time monitoring of live streaming on various
      communication channels. It spots negative phases that standard ﬁlters don't recognize.
    </div>
  </div>
);

const CrmBotCard = () => (
  <div className={`${st.card} ${st.crmbot}`}>
    <div className={st.card_title}>Supporting CRM Bot in developing their AI-based product</div>
    <div className={st.card_description}>
      CRM Bot is a conversational AI platform designed to build intelligent chatbots and voice bots powered by
      enterprise data. CRM Bot can be used in external and internal communication.
    </div>{' '}
    <div className={st.card_button}>
      <a href='https://crmbot.ai/' rel='noreferrer nofollow' target='_blank'>
        <Button className='secondary' stClassName={`${st.button}`}>
          See more
        </Button>
      </a>
    </div>
  </div>
);

const GqpCard = () => (
  <div className={`${st.card} ${st.gqp}`}>
    <div className={st.card_title}>GraphQL Poland online events led by our Community members</div>
    <div className={st.card_description}>
      We support our IT Community members in realizing their interests and taking new initiatives. We value people who
      want to invest their time and skills in knowledge sharing.
    </div>
    <div className={st.card_button}>
      {' '}
      <a href='https://www.meetup.com/graphql-poland/' rel='noreferrer nofollow' target='_blank'>
        <Button className='primary' stClassName={`${st.button} ${st.gqp_button}`}>
          See more
        </Button>
      </a>
    </div>
  </div>
);

const MedicaCard = () => (
  <div className={`${st.card} ${st.medica}`}>
    <div className={st.card_title}>
      Promoted our partner’s solution on <span className={st.card_title_blue}>Medica Dusseldorf Forum</span>
    </div>
    <div className={st.card_description}>
      We engaged in the promotion of a medical tool that brings a huge value to people in nursing homes - smart bands
      that collect various parameters and measure the mobility of patients and then send data to the app.
    </div>
  </div>
);
